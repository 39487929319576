import React, { useEffect, useRef, useState } from "react";
import { Button, Switch, TOAST } from "../../../../../retro";
import { useParams } from "react-router-dom";
import { get, post } from "../../../../Network/Axios";
import Sidepane from "./Sidepane";
import useFunctionallityHook from "../../../../Hooks/useFunctionallityHook";
import Modal from "retro/Modal/Modal";
import Input from "retro/Inputs/Input";
import NewSwitchTabs from "retro/InlineTabs/NewSwitchTabs";

export default function index() {
  const { id } = useParams();
  const OFFLINE = useFunctionallityHook().offlineQueues;
  const DEALCODES = useFunctionallityHook().dealCodes;
  const [pg, setPg] = useState("RAZORPAY");
  const [travelConfig, setTravelConfig] = useState({
    flight: 0,
    bus: 0,
    hotel: 0,
    cancellation: 0,
    amendment: 0,
    pg: 0,
    hotelReseller: false,
    pricePerSegment: true,
    flightReseller: false,
    hotelOffer: false,
    flightIntOffer: false,
    flightDomOffer: false,
    flightInt: 0,
    flightCancellation: 0,
    flightIntCancellation: 0,
    hotelCancellation: 0,
    hotelInt: 0,
    hotelIntCancellation: 0,
    pg: 2.33,
  });
  let defaultInsuranceType = undefined;
  const [insuranceType, setInsuranceType] = useState(defaultInsuranceType);

  useEffect(() => {
    const insuranceType =
      travelConfig?.flightDomInsurance === true
        ? "EMBEDDED"
        : travelConfig?.flightDomInsurance === false
        ? "OPT-IN"
        : "OFF";
    setInsuranceType(insuranceType);
  }, [travelConfig?.flightDomInsurance]);

  const [modal, setModal] = useState(undefined);
  const [config, setConfig] = useState({});
  const load = (id) => {
    get(`/clients/apis/${id}`, (e, r) => {
      if (r) {
        setConfig(r.config);
        setTravelConfig({
          ...r.travelConfig,
          pricePerSegment: r.pricePerSegment,
          airlineInvoiceCollectionToggle: r.airlineInvoiceCollectionToggle,
          hotelServicePricePerSegment: r.hotelServicePricePerSegment
        });
        setPg(r.pg);
      }
    });
  };

  const [hotelConfig, setHotelConfig] = useState(
    travelConfig.hotelReseller ? "RESELLER + AGENCY" : "AGENCY"
  );

  useEffect(() => {
    const insuranceType =
      travelConfig?.flightDomInsurance === true
        ? "EMBEDDED"
        : travelConfig?.flightDomInsurance === false
        ? "OPT-IN"
        : "OFF";
    setInsuranceType(insuranceType);
  }, [travelConfig?.flightDomInsurance]);

  useEffect(() => {
    const configType =
      travelConfig?.hotelReseller === true ? "RESELLER + AGENCY" : "AGENCY";
    setHotelConfig(configType);
  }, [travelConfig?.hotelReseller]);

  const updateDomesticInsurance = () => {
    let insuranceVal = undefined;
    if (insuranceType === "OFF") {
      insuranceVal = 2;
    } else if (insuranceType === "EMBEDDED") {
      insuranceVal = 1;
    } else if (insuranceType === "OPT-IN") {
      insuranceVal = 0;
    }
    post(
      `/clients/apis/${id}/settings`,
      {
        key: "flightDomInsurance",
        value: insuranceVal,
      },
      (e, r) => {
        if (r) {
          load(id);
          TOAST.success("Configuaration updated successfully!");
        }
        if (e) {
          TOAST.handleError(e);
        }
      }
    );
  };

  const updateHotelConfig = () => {
    post(
      `/clients/apis/${id}/settings`,
      {
        key: "hotelReseller",
        value: 1,
      },
      (e, r) => {
        if (r) {
          load(id);
          TOAST.success("Reseller toggled successfully!");
        }
      }
    );
  };

  useEffect(() => {
    load(id);
  }, []);
  const PLUGINS = [
    {
      name: "Cleartrip Flight",
      plugin: "CL_FLIGHT",
      default: true,
      supportReseller: true,
    },
    {
      name: "Cleartrip International Flight",
      plugin: "CL_FLIGHT_INT",
      default: true,
    },
    {
      name: "TJ Flight",
      plugin: "TJ_FLIGHT",
      default: true,
      supportReseller: true,
    },
    {
      name: "Cleartrip Hotel",
      plugin: "CL_HOTEL",
      supportReseller: true,
      default: false,
    },
    {
      name: "Cleartrip Hotel International",
      plugin: "CL_HOTEL_INT",
      supportReseller: true,
      default: true,
    },
    {
      name: "Cleartrip Assured Hotels",
      plugin: "CL_HOTEL_ASSURED",
      supportReseller: true,
      default: false,
    },
    {
      name: "Cleartrip Hotel Agency",
      plugin: "CL_HOTEL_AGENCY",
      supportReseller: false,
      default: false,
    },
    {
      name: "Cleartrip Hotel Offline",
      plugin: "CL_HOTEL_OFFLINE",
      supportReseller: true,
      default: true,
    },
    {
      name: "Contracted Hotels",
      plugin: "CONTRACTED",
      supportReseller: true,
      default: true,
    },
    {
      name: "Travel yaari bus",
      plugin: "TY_BUS",
      default: true,
    },
    {
      name: "Cleartrip V3 Hotel Reseller",
      plugin: "CL_V3_RESELLER",
      default: false,
      supportReseller: false,
    },
    {
      name: "Cleartrip V3 Hotel Agency",
      plugin: "CL_V3_AGENCY_ONLY",
      default: false,
      supportReseller: false,
    },
    {
      name: "Cleartrip V3 Hotel Pure Agency",
      plugin: "CL_V3_PURE_AGENCY",
      default: false,
      supportReseller: false,
    },
    {
      name: "Cleartrip V3 Hotel Reseller Offline (For Testing)",
      plugin: "CL_V3_RESELLER_OFFLINE",
      default: false,
      supportReseller: false,
    }
  ];
  const STAGE = [
    {
      name: "Cleartrip QA Server(For Testing)",
      plugin: "CL_FLIGHT_QA",
      default: false,
      supportReseller: false,
    },
    {
      name: "TJ Flight Qa(For Testing)",
      plugin: "TJ_FLIGHT_QA",
      default: false,
      supportReseller: false,
    },
    {
      name: "Cleartrip Hotel Offline",
      plugin: "CL_HOTEL_OFFLINE",
      supportReseller: true,
      default: true,
    },
    {
      name: "Travel yaari Qa (For Testing)",
      plugin: "TY_BUS_QA",
      default: false,
      supportReseller: false,
    },
    {
      name: "Zuna Hotel (For testing)",
      plugin: "ZUNA_HOTEL",
      default: false,
      supportReseller: false,
    },
    {
      name: "Cleartrip V3 Hotel Reseller (For Testing)",
      plugin: "CL_V3_RESELLER",
      default: false,
      supportReseller: false,
    },
    {
      name: "Cleartrip V3 Hotel Agency (For Testing)",
      plugin: "CL_V3_AGENCY_ONLY",
      default: false,
      supportReseller: false,
    },
    {
      name: "Cleartrip V3 Hotel Pure Agency  (For Testing)",
      plugin: "CL_V3_PURE_AGENCY",
      default: false,
      supportReseller: false,
    },
    {
      name: "Cleartrip V3 Hotel Reseller Offline (For Testing)",
      plugin: "CL_V3_RESELLER_OFFLINE",
      default: false,
      supportReseller: false,
    }
  ];
  const TRAVELCHARGES = [
    {
      name: "flight",
      label: "Flight Charges",
      description: "Service charges for flight",
      placeholder: "Enter flight charges",
    },
    {
      name: "flightInt",
      label: "International Flight Charges",
      description: "Service charges for international flight",
      placeholder: "Enter flight charges",
    },
    {
      name: "bus",
      label: "Bus Charges",
      description: "Service charges for bus",
      placeholder: "Enter bus charges",
    },
    {
      name: "cancellation",
      label: "Other Products Cancellation Charges",
      description: "Other Products Cancellation Charges",
      placeholder: "Enter cancellation charges",
    },
    {
      name: "flightCancellation",
      label: "Flight Cancellation Charges",
      description: "Flight Cancellation Charges",
      placeholder: "Enter cancellation charges",
    },
    {
      name: "flightIntCancellation",
      label: "Intl. Flight Cancellation Charges",
      description: "International Flight Cancellation Charges",
      placeholder: "Enter cancellation charges",
    },
    {
      name: "amendment",
      label: "Amendment Charges",
      description: "Amendment Charges",
      placeholder: "Enter Amendment Charges",
    },
    {
      name: "visa",
      label: "Visa",
      description: "Service charges for visa",
      placeholder: "Enter visa charges",
    },
    {
      name: "insurance",
      label: "Insurance Charges",
      description: "Service charges for insurance",
      placeholder: "Enter insurance charges",
    },
    {
      name: "pg",
      label: "PG Charges",
      description: "Pg charges for payment gateway",
      placeholder: "Enter pg charges",
    },
    ...(OFFLINE
      ? [
          {
            name: "train",
            label: "Train General Charges",
            description: "Service charges for train",
            placeholder: "Enter train charges",
          },
          {
            name: "trainAc",
            label: "Train AC Charges",
            description: "Service charges for train",
            placeholder: "Enter train charges",
          },
          {
            name: "trainTatkal",
            label: "Train Tatkal Charges",
            description: "Service charges for train",
            placeholder: "Enter train charges",
          },
          {
            name: "trainCancellation",
            label: "Train cancellation charges",
            description: "Train Cancellation Charges",
            placeholder: "Enter cancellation charges",
          },
        ]
      : []),
    {
      name: "hotel",
      label: "Hotel Service Charges",
      description: "Hotel Service Charges",
      placeholder: "Enter hotel charges",
    },
    {
      name: "hotelCancellation",
      label: "Hotel Cancellation Charges",
      description: "Hotel Cancellation Charges",
      placeholder: "Enter cancellation charges",
    },
    {
      name: "hotelInt",
      label: "Hotel International Charges",
      description: "Service charges for International Hotels",
      placeholder: "Enter hotel interntional charges",
    },
    {
      name: "hotelIntCancellation",
      label: "Hotel International Cancellation Charges",
      description: "Cancellation Charges for Internationa Hotel",
      placeholder: "Enter cancellation charges",
    },
  ];
  if (travelConfig?.flightDomInsurance !== undefined) {
    TRAVELCHARGES.push({
      name: "ctFlightDomInsuranceFee",
      label: "Cleartrip Domestic Flight Insurance Charges",
      description: "Service charges for insurance",
      placeholder: "Enter insurance charges",
    });
    TRAVELCHARGES.push({
      name: "defaultFlightDomInsuranceFee",
      label: "Default Domestic Flight Insurance Charges",
      description: "Service charges for insurance",
      placeholder: "Enter insurance charges",
    });
  }

  const hotelTabs = [
    { name: "AGENCY", disabled: false },
    { name: "RESELLER + AGENCY", disabled: false },
    { name: "RESELLER", disabled: true },
  ];

  return (
    <div>
      <h2 className="fw-bold mb2">Configuration</h2>
      <div className="border pd2 flex horizontally center-vertically">
        <div className="flex-1">
          <h3 className="flex-1">Airline Invoice Collection</h3>
          <p>Do you want to collect airline invoice on ooo-cockpit.</p>
        </div>
        <Switch
          onChange={() => {
            post(`/clients/apis/${id}/emailToggle`, {}, (e, r) => {
              if (r) {
                load(id);
                TOAST.success("Collection toggled successfully!");
              }
            });
          }}
          state={travelConfig.airlineInvoiceCollectionToggle}
        />
      </div>
      <div className="border pd2 flex horizontally center-vertically">
        <div className="flex-1">
          <h3 className="flex-1">Hotel Price Per Segment</h3>
          <p>If this is switched off then service charge will be per booking.</p>
        </div>
        <Switch
          onChange={() => {
            post(`/clients/apis/${id}/hotelPricing`, {}, (e, r) => {
              if (r) {
                load(id);
                TOAST.success("Hotel segment toggled successfully!");
              }
            });
          }}
          state={travelConfig.hotelServicePricePerSegment}
        />
      </div>
      <div className="border pd2 mb4 flex horizontally center-vertically">
        <div className="flex-1">
          <h3 className="flex-1">Flight/Bus Price Per Segment</h3>
          <p>If this is switched off then service charge will be per booking</p>
        </div>
        <Switch
          onChange={() => {
            post(`/clients/apis/${id}/pricing`, {}, (e, r) => {
              if (r) {
                load(id);
                TOAST.success("Segment toggled successfully!");
              }
            });
          }}
          state={travelConfig.pricePerSegment}
        />
      </div>
      <h2 className="fw-bold mb2">Pg configuration</h2>
      <div className="border pd2 flex horizontally center-vertically">
        <div className="flex-1">
          <h3 className="flex-1">Paytm</h3>
          <p>Do you want to enable paytm for this client.</p>
        </div>
        <Switch
          onChange={() => {
            post(
              `/clients/apis/${id}/pg`,
              {
                pg: pg === "PAYTM" ? "RAZORPAY" : "PAYTM",
              },
              (e, r) => {
                if (r) {
                  load(id);
                  TOAST.success("Pg toggled successfully!");
                }
              }
            );
          }}
          state={pg === "PAYTM"}
        />
      </div>

      <h2 className="fw-bold mt4 mb2">Offers</h2>
      <div className="border pd2 flex horizontally center-vertically">
        <div className="flex-1">
          <h3 className="flex-1">Hotel Offers</h3>
          <p>Do you want to show hotel offers</p>
        </div>
        <Switch
          onChange={() => {
            post(
              `/clients/apis/${id}/settings`,
              {
                key: "hotelOffer",
                value: 1,
              },
              (e, r) => {
                if (r) {
                  load(id);
                  TOAST.success("Offer toggled successfully!");
                }
              }
            );
          }}
          state={travelConfig.hotelOffer}
        />
      </div>
      <div className="border pd2 flex horizontally center-vertically">
        <div className="flex-1">
          <h3 className="flex-1">International Flight Offer</h3>
          <p>Do you want to show offers on international flight</p>
        </div>
        <Switch
          onChange={() => {
            post(
              `/clients/apis/${id}/settings`,
              {
                key: "flightIntOffer",
                value: 1,
              },
              (e, r) => {
                if (r) {
                  load(id);
                  TOAST.success("Offer toggled successfully!");
                }
              }
            );
          }}
          state={travelConfig.flightIntOffer}
        />
      </div>
      <div className="border pd2 flex horizontally center-vertically">
        <div className="flex-1">
          <h3 className="flex-1">Domestic Flight Offer</h3>
          <p>Do you want to show offers on domestic flight</p>
        </div>
        <Switch
          onChange={() => {
            post(
              `/clients/apis/${id}/settings`,
              {
                key: "flightDomOffer",
                value: 1,
              },
              (e, r) => {
                if (r) {
                  load(id);
                  TOAST.success("Offer toggled successfully!");
                }
              }
            );
          }}
          state={travelConfig.flightDomOffer}
        />
      </div>
      {/* <h2 className="fw-bold mt4 mb2">
				Reseller configuration
			</h2>
			<div className="border pd2 flex horizontally center-vertically">
				<div className="flex-1">
					<h3 className="flex-1">
						Hotel Reseller
					</h3>
					<p>
						Do this account supports reseller invoices for hotels.
					</p>
				</div>
				<Switch onChange={() => {
					post(`/clients/apis/${id}/settings`, {
						key: "hotelReseller",
						value: 1
					}, (e, r) => {
						if (r) {
							load(id)
							TOAST.success("Reseller toggled successfully!")
						}
					})
				}} state={travelConfig.hotelReseller}/>
			</div> */}

      <h2 className="fw-bold mt4 mb2">Reseller Configuration</h2>
      <div className="border pd2">
        <NewSwitchTabs
          title="Hotel Reseller Configuration"
          titleStyle={{ color: "black", fontWeight: 600, fontSize: "16px" }}
          tabs={hotelTabs}
          active={hotelConfig}
          onClick={(item) => {
            setHotelConfig(item);
            post(
              `/clients/apis/${id}/settings`,
              {
                key: "hotelReseller",
                value: 1,
              },
              (e, r) => {
                if (r) {
                  load(id);
                  TOAST.success("Reseller toggled successfully!");
                }
                if (e) {
                  TOAST.handleError(e);
                }
              }
            );
          }}
        />
        {/* <Button className='btn btn-black btn-sm' onClick={updateHotelConfig} >Update</Button> */}
        {/* <Seperator margin={4}/>
				<SwitchTabs title="International Flight Insurance Configuration" titleStyle={{color:'black', fontWeight:600, fontSize:'16px'}} tabs={["OFF","EMBEDDED", 'OPT-IN']} 
				active={insuranceType} onClick={item=>setInsuranceType(item)}/>
				<Button className='btn btn-black btn-sm'>Update</Button> */}
      </div>

      <h2 className="fw-bold mt4">Travel Settings</h2>
      <div className="card-container mt2">
        {TRAVELCHARGES.map((charge) =>
          charge.name === "pg" ? (
            <PgChargesSettings
              name={charge.name}
              label={charge.label}
              id={id}
              charges={travelConfig[charge.name]}
              description={charge.description}
              reload={load}
              placeholder={charge.placeholder}
            />
          ) : (
            <TravelChargesSettings
              name={charge.name}
              label={charge.label}
              id={id}
              charges={travelConfig[charge.name]}
              description={charge.description}
              reload={load}
              placeholder={charge.placeholder}
            />
          )
        )}
      </div>
      {DEALCODES && (
        <>
          <h2 className="fw-bold mt4">Production Travel Plugins</h2>
          <div className="card-container mt2">
            {PLUGINS.map((item) => {
              let pluginConfig = config[item.plugin];
              let enabled = pluginConfig ? pluginConfig.enabled : item.default;
              return (
                <div className="retro-card bg-white" key={item.name}>
                  <div
                    className="absolute"
                    style={{
                      right: 15,
                      top: 10,
                    }}
                  >
                    <Switch
                      state={enabled}
                      onChange={() => {
                        post(
                          `/clients/apis/${id}/toggle`,
                          { type: item.plugin },
                          (e, r) => {
                            if (r) {
                              load(id);
                            }
                          }
                        );
                      }}
                      size="large"
                    />
                  </div>
                  <h3 className="fw-bold text-truncate" style={{width: '265px'}}>{item.name}</h3>
                  <p>{item.plugin}</p>
                  <p
                    onClick={() => {
                      setModal(item.plugin);
                    }}
                    className="btn btn-black mt2"
                  >
                    Manage Details
                  </p>
                </div>
              );
            })}
          </div>
          <h2 className="fw-bold mt4">Stage Travel Plugins</h2>
          <div className="card-container mt2">
            {STAGE.map((item) => {
              let pluginConfig = config[item.plugin];
              let enabled = pluginConfig ? pluginConfig.enabled : item.default;
              return (
                <div className="retro-card bg-white" key={item.name}>
                  <div
                    className="absolute"
                    style={{
                      right: 15,
                      top: 10,
                    }}
                  >
                    <Switch
                      state={enabled}
                      onChange={() => {
                        post(
                          `/clients/apis/${id}/toggle`,
                          { type: item.plugin },
                          (e, r) => {
                            if (r) {
                              load(id);
                            }
                          }
                        );
                      }}
                      size="large"
                    />
                  </div>
                  <h3 className="fw-bold text-truncate" style={{width: '265px'}}>{item.name}</h3>
                  <p>{item.plugin}</p>
                  <p
                    onClick={() => {
                      setModal(item.plugin);
                    }}
                    className="btn btn-black mt2"
                  >
                    Manage Details
                  </p>
                </div>
              );
            })}
          </div>
        </>
      )}
      {modal !== undefined && (
        <Sidepane
          id={id}
          config={config}
          reload={() => {
            load(id);
          }}
          setConfig={setConfig}
          modal={modal}
          onClose={() => {
            setModal(undefined);
          }}
        />
      )}
    </div>
  );
}

const PgChargesSettings = ({
  name = "",
  id,
  reload,
  charges = 0,
  label = "",
  description = "",
}) => {
  const [modal, setModal] = useState(false);
  const [value, setValue] = useState(charges);
  useEffect(() => {
    setValue(charges);
  }, [charges]);
  return (
    <div className="retro-card">
      {modal && (
        <Modal
          actions={
            <Button
              className="btn btn-primary"
              onClick={() => {
                post(
                  `/clients/apis/${id}/settings`,
                  {
                    key: name,
                    value: value,
                  },
                  (e, r) => {
                    if (r) {
                      reload(id);
                      TOAST.success("Charges updated successfully!");
                      setModal(false);
                    }
                  }
                );
              }}
            >
              Update {label}
            </Button>
          }
          onClose={() => {
            setModal(false);
          }}
          description="Enter charges below"
          title={`Update ${label}`}
        >
          <Input
            onChange={(value) => {
              value = parseFloat(value);
              value = value;
              setValue(value);
            }}
            type="number"
            defaultValue={charges.toFixed(2)}
            label="Enter base charges here"
          />
          <div className="text-small mt2 flex horizontal" style={{color:"red"}}>
            <p> Charges less than 5 will be automatically considered as % otherwise as a flat amount.</p>
          </div>
        </Modal>
      )}
      <h2 className="fw-bold">{label}</h2>
      <p className="text-small text-light">{description}</p>
      <div className="text-small mt2 mb2">
        {`Pg Charges: ${charges.toFixed(2)}${charges<5?"%":""}`}
      </div>
      <Button
        onClick={() => {
          setModal(true);
        }}
        className="btn btn-link"
      >
        Update
      </Button>
    </div>
  );
};

const TravelChargesSettings = ({
  name = "",
  id,
  reload,
  charges = 0,
  label = "",
  description = "",
}) => {
  const [modal, setModal] = useState(false);
  const [value, setValue] = useState(charges);
  let base = charges / 1.18;
  let gst = charges - base;
  let total = base + gst;
  useEffect(() => {
    setValue(charges);
  }, [charges]);
  return (
    <div className="retro-card">
      {modal && (
        <Modal
          actions={
            <Button
              className="btn btn-primary"
              onClick={() => {
                post(
                  `/clients/apis/${id}/settings`,
                  {
                    key: name,
                    value: value,
                  },
                  (e, r) => {
                    if (r) {
                      reload(id);
                      TOAST.success("Charges updated successfully!");
                      setModal(false);
                    }
                  }
                );
              }}
            >
              Update {label}
            </Button>
          }
          onClose={() => {
            setModal(false);
          }}
          description="Enter charges below"
          title={`Update ${label}`}
        >
          <Input
            onChange={(value) => {
              value = parseFloat(value);
              value = value + value * 0.18;
              setValue(value);
            }}
            type="number"
            defaultValue={(charges / 1.18).toFixed(2)}
            label="Enter base charges here"
          />
          <p className="text-small mt2">
            All service charges are exclusive of gst. See final charges after
            gst implication below.
          </p>
          <p className="mt2">
            Base: {(value / 1.18).toFixed(2)} + Gst{" "}
            {(value - value / 1.18).toFixed(2)} = Total {value.toFixed(2)}
          </p>
        </Modal>
      )}
      <h2 className="fw-bold">{label}</h2>
      <p className="text-small text-light">{description}</p>
      <div className="text-small mt2 mb2">
        Base: {base.toFixed(2)} + Gst {gst.toFixed(2)} = Total{" "}
        {total.toFixed(2)}
      </div>
      <Button
        onClick={() => {
          setModal(true);
        }}
        className="btn btn-link"
      >
        Update
      </Button>
    </div>
  );
};
